export default {
    colors: {
        text: "#fff",
        headerBackground: "#35CCD8",
        headerText: "white",
        paperBackgroundColor: "#25252A",
        paperHoverBackgroundColor: "#2a2a31",
        paperHeadingColor: "#EDEDEE",
        paperHoverHeadingColor: "#8C87E6",
        paperBorderColor: "transparent",
        paperDescriptionColor: "#BEBEC6",
        collectionBackgroundColor: "#2C2C31",
        collectionHeading: "#EDEDEE",
        collectionDescription: "#BEBEC6",
        breadcrumbLinkTextColor: "#BEBEC6",
        breadcrumbHoverLinkTextColor: "#8C87E6",
        breadcrumbTextColor: "#EDEDEE",
        articleTextColor: "#EDEDEE",
        articleDescriptionColor: "#BEBEC6",
        background: "#222127",
        primary: "#EDEDEE",
        secondary: "#EDEDEE",
        muted: "#EDEDEE",
        searchTextColor: "rgba(255,255,255,0.7)",
        searchTextFocusColor: "#EDEDEE",
        searchTextPlaceholderColor: "white",
        searchTextFocusPlaceholderColor: "#ABB2BF",
        comboboxColor: "white",
        iconColor: "#5747FF",
        logoColor: "white",
        footerTextColor: "#a8b6c2",
        footerTextHoverColor: "#a8b6c2",
    },
    radii: [0, 2, 4, 8],
    fonts: {
        body: "system-ui, sans-serif",
        heading: "system-ui, sans-serif",
        monospace: "Menlo, monospace",
    },
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        small: 1.2,
        body: 1.5,
        heading: 1.125,
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    /* Styles for MDX */
    styles: {
        // the keys used here reference elements in MDX
        h1: {
            // the style object for each element
            // can reference other values in the theme
            fontFamily: "heading",
            fontWeight: "heading",
            lineHeight: "heading",
            marginTop: 0,
            marginBottom: 3,
        },
        a: {
            color: "paperHoverHeadingColor",
            ":hover, :focus": {
                color: "iconColor",
            },
        },
        blockquote: {
            marginLeft: "1.75rem",
        },
        // more styles can be added as needed
    },
}